import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: (v) => v !== undefined && v > 1 ? "y" : "y",
      mo: (v) => v !== undefined && v > 1 ? "mo" : "mo",
      w: (v) => v !== undefined && v > 1 ? "week" : "weeks",
      d: (v) => v !== undefined && v > 1 ? "day" : "days",
      h: (v) => v !== undefined && v > 1 ? "hours" :"hour",
      m: (v) => v !== undefined && v > 1 ? "mins" : "min",
    },
  },
});

export const hasEventStarted = (isoDate: string) => {
  const date = DateTime.fromISO(isoDate);
  const duration = date.diffNow('milliseconds');
  return duration.milliseconds <= 0;
};

export const calculateDuration = (isoDate: string) => {
  const date = DateTime.fromISO(isoDate);
  const duration = date.diffNow('milliseconds');
  return shortEnglishHumanizer(duration.milliseconds, { units: ['d', 'h', 'm'], round: true });
};
