import { useCallback } from "react";
import { useActiveEvent } from "../../hooks/useActiveEvent";
import {
  Background,
  Container,
  Footer,
  ImageContent,
  LargeButton,
  Link,
  Page,
  TitleText,
} from "./AcceptInvitePage.styles";
import { DateSegment } from "./components/DateSegment/DateSegment";
import { LocationSegment } from "./components/LocationSegment/LocationSegment";
import { useNavigate } from "react-router";
import { clearAll } from "../../services/storage";
import { GuestList } from "../../components/GuestList/GuestList";

export const AcceptInvitePage = () => {
  const { event, updateGuestAccept } = useActiveEvent();
  const navigate = useNavigate();
  
  const handleAccept = useCallback(async () => {
    await updateGuestAccept(true);
    navigate('/home');
  }, [navigate, updateGuestAccept]);

  const handleDecline = useCallback(async () => {
    clearAll();
    navigate('/sign-in');
  }, [navigate]);

  if (!event) {
    return null;
  }

  return (
    <Background>
      <Page>
        <div>
          <img src="/assets/images/logo.svg" alt="" />
          <Container>
            <img src="/assets/images/invite_background.png" alt="" />
            <ImageContent>
              <TitleText>
                You’re invited...
              </TitleText>
              <TitleText>
                {event.title}
              </TitleText>
            </ImageContent>

            <DateSegment startTime={event.startTime} />
            <LocationSegment location={event.location} venue={event.venue} />

            <GuestList guests={event.guests} />
          </Container>

          <Footer>
            <LargeButton onClick={handleAccept}>
              Accept
            </LargeButton>
            <Link onClick={handleDecline}>
              Decline
            </Link>
          </Footer>
        </div>
      </Page>
    </Background>
  );
};
