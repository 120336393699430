import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
`;

export const TitleSubText = styled.div`
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.1px;
  padding-bottom: 8px;
`;
