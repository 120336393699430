import { useMemo } from "react";
import { AccountBalance } from "../../../../services/bankService.types";
import { Event, User } from "../../../../services/firebase.types";
import { moneyValueFromMinor } from "../../../../utils/currencyUtils";
import { Amount, Avatars, Container, Row, SmallText, Title } from "./PoolCard.styles";

interface PoolCardProps {
  amountPaid?: number;
  event: Event;
  balance: AccountBalance;
  user: User;
}

export const PoolCard = ({ amountPaid, balance, event, user }: PoolCardProps) => {
  const userGuest = useMemo(() => event.guests.find(e => e.firstName === user.firstName && e.lastName === user.lastName), [event, user]);
  return (
    <Container>
      <Title>
        {`💸 ${user.firstName}’s pool`}
      </Title>

      <div>
        <SmallText>
          Total in pool:
        </SmallText>
        <Amount>
          {moneyValueFromMinor(balance.amount.minorUnits)}
        </Amount>
        <Row>
          {userGuest ? (
            <Avatars>
              <div>
                <img src={userGuest?.avatarUrl} alt="" />
              </div>
            </Avatars>
          ) : null}
          {amountPaid ? (
            <SmallText>
              You paid: {moneyValueFromMinor(amountPaid)}
            </SmallText>
          ) : null}
        </Row>
      </div>
    </Container>
  );
};
