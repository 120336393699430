import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from 'axios-retry';
import { STORAGE_KEY, getItem } from "./storage";
import { Event, Guest, User } from "./firebase.types";
import {
  AccountBalance,
  AccountHolder,
  AccountSpaces,
  AccountTransactionResponse,
  AccountsResponse,
  Activity,
  FeedItem,
} from "./bankService.types";
import mockAccounts from "./mockData/accounts.json";
import mockAccountHolder from "./mockData/accountHolder..json";
import mockAccountBalance from "./mockData/accountBalance.json";
import mockAccountSpaces from "./mockData/accountSpaces.json";
import mockAccountTransactions from "./mockData/accountTransactions.json";
import { getConfig, localConfig } from "./config";
import { DateTime, Interval } from "luxon";

axiosRetry(axios, {
  retryCondition: (error: AxiosError<unknown, any>) => {
    if (error.response?.status === 429) {
      return true;
    }
    return true;
  },
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
});

/*
* use jquery to get accounts with auth token header
* $  curl -H "Authorization: Bearer <personal access token>"
https://api.starlingbank.com/api/v2/accounts
* */
const corsApi = "https://corsproxy.org/?";

const api = <T>({
  url,
  ...rest
}: AxiosRequestConfig<any>): Promise<AxiosResponse<T>> => {
  const apiKey = getItem<string>(STORAGE_KEY.ApiKey);
  const headers = {
    Authorization: `Bearer ${apiKey}`,
  };
  const routeUrl = url ? `${corsApi}${encodeURIComponent(url)}` : "";

  return axios({
    headers,
    method: "GET",
    url: routeUrl,
    ...rest,
  });
};

export const getAccounts = async () => {
  const config = await getConfig();
  if (config?.mockData) {
    return mockAccounts?.accounts;
  }

  const { data } = await api<AccountsResponse>({
    url: "https://api.starlingbank.com/api/v2/accounts",
  });
  return data?.accounts;
};

export const getHolderName = async () => {
  const config = await getConfig();
  if (config?.mockData) {
    return mockAccountHolder;
  }

  const { data } = await api<AccountHolder>({
    url: "https://api.starlingbank.com/api/v2/account-holder/individual",
  });
  return data;
};

export const getBalanceForAccount = async (accountId: string) => {
  const config = await getConfig();
  if (config?.mockData) {
    return mockAccountBalance;
  }

  const { data } = await api<AccountBalance>({
    url: `https://api.starlingbank.com/api/v2/accounts/${accountId}/balance`,
  });
  return data;
};

export const getSpendingSpaces = async (accountId: string) => {
  const config = await getConfig();
  if (config?.mockData) {
    return mockAccountSpaces;
  }

  const { data } = await api<AccountSpaces>({
    url: `https://api.starlingbank.com/api/v2/account/${accountId}/spaces`,
  });
  return data;
};

export const getTransactionsForAccount = async (accountId: string, categoryId: string) => {
  const config = await getConfig();
  if (config?.mockData) {
    return mockAccountTransactions?.feedItems;
  }

  let minTransactionTimestamp = new Date();
  minTransactionTimestamp.setDate(minTransactionTimestamp.getDate() - 90); //10 days ago
  let maxTransactionTimestamp = new Date();

  const startDate = encodeURIComponent(minTransactionTimestamp.toISOString());
  const endDate = encodeURIComponent(maxTransactionTimestamp.toISOString());
  // const dateParams = `/settled-transactions-between?minTransactionTimestamp=${startDate}&maxTransactionTimestamp=${endDate}`;
  const dateParams = `/category/${categoryId}/transactions-between?minTransactionTimestamp=${startDate}&maxTransactionTimestamp=${endDate}`;
  const { data } = await api<AccountTransactionResponse>({
    url: `https://api.starlingbank.com/api/v2/feed/account/${accountId}${dateParams}`,
  });

  return data?.feedItems;
};

export const calculateTransactions = (user: User, event: Event, transactions: FeedItem[], onlyForEvent: boolean) => {
  const config = localConfig();
  const guests = [...event.guests];

  let eventTransactions = [...transactions];
  if (onlyForEvent) {
    const eventStartTime = DateTime.fromISO(event.startTime).minus({ hours: config.collectTransactionsAheadOfEventInHours || 0 });
    const eventInterval = Interval.fromDateTimes(eventStartTime, DateTime.local());
    eventTransactions = transactions.filter(a => {
      const transTime = DateTime.fromISO(a.transactionTime);
      return eventInterval.contains(transTime);
    });
  }

  // get transaction where counterPartyName contains lastName
  const myTransactions = eventTransactions.filter((item) => {
    return item.counterPartyName.includes(user.lastName);
  });

  const activities: Activity[] = myTransactions.map(t => ({
    isUser: true,
    direction: t.direction === 'IN' ? 'IN' : 'OUT',
    guest: user as unknown as Guest,
    transaction: t,
    amount: t.amount,
    created: t.transactionTime,
  }));

  // set paid to true where transactions counterPartyName contains lastName for each user
  guests.forEach((guest) => {
    eventTransactions.forEach((transaction) => {
      if (!(guest.firstName === user.firstName && guest.lastName === user.lastName) && transaction.counterPartyName.includes(guest.lastName)) {
        activities.push({
          direction: transaction.direction === 'IN' ? 'IN' : 'OUT',
          guest,
          amount: transaction.amount,
          transaction,
          isUser: false,
          created: transaction.transactionTime,
        });

      } else if (
        guest.role?.toLowerCase() === 'admin' &&
        transaction.counterPartyType === 'MERCHANT' &&
        transaction.direction === 'OUT'
      ) {
        activities.push({
          direction: 'OUT',
          guest,
          amount: transaction.amount,
          transaction,
          isUser: false,
          created: transaction.transactionTime,
        });
      }
    });
  });

  const amountPaid = activities.filter(e => e.isUser && e.direction === 'IN').reduce((acc, act) => acc + act.amount.minorUnits, 0);

  return {
    amountPaid,
    activities: activities.sort((a: Activity, b: Activity) => a.created > b.created ? -1 : 0),
  };
};

export const addFunds = async (paymentLink: string, amount: number): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const minorUnit = amount;
      const paymentTab = window.open(
        // `https://settleup.starlingbank.com/robertbarbour?message=Chippin%20001&amount=${minorUnit}`,        
        `${paymentLink}&amount=${minorUnit}`,
        "_blank"
      );
      const tabCheck = setInterval(function () {
        if (paymentTab?.closed) {
          clearInterval(tabCheck);
          resolve();
        }
      }, 250); // check every 250ms
    } catch (ex) {
      reject(ex);
    }
  });
};
