import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 34px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  margin-top: 15px;

  img {
    margin-right: 12px;
    height: 48px;
    width: 48px;
  }
`;

export const TitleText = styled.div`
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const ValueText = styled.div`
  color: var(--typography-light, #9D9BA7);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.1px;
`;

export const Box = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
