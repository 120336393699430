import { useCallback } from "react";
import { Header } from "../../components/Header/Header";
import { CheckItem, CheckList, LeftComment, Container, Footer, LargeButton, Page, TitleText, RightComment, Background } from './LandingPage.styles';
import { useNavigate } from "react-router-dom";

const checkItems = [
  'Shared digital wallet',
  'Shared account balances',
  'Real-time payment notifications',
  'Individual virtual debit card',
  'Simple top-ups and transfer',
];

export const LandingPage = () => {
  const navigate = useNavigate();
  const handleSignIn = useCallback(() => {
    navigate('/sign-in');
  }, [navigate]);

  return (
    <Background>
      <Page>
        <div>
          <Header onLoginClick={handleSignIn} />
          <Container>
            <TitleText>
              THE GROUP WALLET AND PAYMENT APP
            </TitleText>
            <h1>
              Everyone can<br /><strong>Chippin</strong> and pay.
            </h1>
            <TitleText style={{ paddingTop :16 }}>
              <strong>Chippin makes it super-easy for groups to share a digital wallet and spend from it.</strong>
            </TitleText>

            <CheckList>
              {checkItems.map(item => (
                <CheckItem>
                  <img src="/assets/images/checkmark.svg" alt="" />
                  {item}
                </CheckItem>
              ))}
            </CheckList>

            <LargeButton onClick={handleSignIn}>
              Apply now
            </LargeButton>
          </Container>
        </div>

        <Footer>
          <LeftComment topInset={26}>
            🎉 Tom has invited you to join him at "Friday<br />Drinks"
          </LeftComment>
          <RightComment topInset={180}>
            💸 Tom has spent £25 on drinks "The Horse<br />and Groom"
          </RightComment>
          <RightComment topInset={240}>
            ✅ Tom,Jacob,Ryan added £10 to ”Friday<br />drinks”
          </RightComment>
          <img src="/assets/images/phone.png" alt="Chippin app" />
        </Footer>
      </Page>
    </Background>
  );
};
