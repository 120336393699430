import styled from "styled-components";
import { Button } from "../../components/Button/Button";

export const Background = styled.div`
  background: rgba(13, 1, 62, 0.10);
  background-image: url('/assets/images/background03.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    /* height: 100%; */

    > img:first-child {
      padding: 24px 24px 0;
    }

    h1 {
      color: #FFF;
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 35.2px */
      letter-spacing: -0.1px;
    }
    
    .carousel-root {
      .thumbs-wrapper {
        display: none;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    font-size: 16px;
    line-height: 20px;
    margin-top: 24px;

    button {
      border-radius: 100px;
      border: 1px solid #FFF;
      background: rgba(255, 255, 255, 0.10);
      box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
      backdrop-filter: blur(6px);
      color: #FFF;
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const TitleText = styled.div`
  color: #FFF;
  font-size: 32px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.1px;
`;

export const ButtonContainer = styled.div`
  padding: 16px 24px 8px;
`;

export const LargeButton = styled(Button)`
  align-self: center;
  padding: 4px 16px;
  height: 50px;
  width: 100%;
`;

export const CloseLink = styled.div`
  color: var(--typography-dark, #0B0A1F);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.1px;
  padding: 21px 24px;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 400px);
  margin-top: 10px;
`;
