import { Activity } from "../../services/bankService.types";
import { ActivityItem } from "../ActivityItem/ActivityItem";
import { Container, TitleSubText } from "./GroupActivity.styles";

interface GroupActivityProps {
  activities?: Activity[];
  eventTitle: string;
}

export const GroupActivity = ({ activities, eventTitle }: GroupActivityProps) => {
  return (
    <Container>
      <TitleSubText>
        Group activity
      </TitleSubText>

      {activities?.map((activity, idx) => (
        <ActivityItem key={idx} activity={activity} eventTitle={eventTitle} />
      ))}
    </Container>
  );
};