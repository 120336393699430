import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { STORAGE_KEY, getItem } from "../../services/storage";
import { User } from "../../services/firebase.types";

export const ProtectedRoute = ({ children }: PropsWithChildren): any => {
  const user = getItem<User>(STORAGE_KEY.User);
  const apiKey = getItem<string>(STORAGE_KEY.ApiKey);
  if (!user || !apiKey) {
    return <Navigate to="/sign-in" replace />;
  }
  return children;
};
