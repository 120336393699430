import { useMemo } from "react";
import { Activity } from "../../../../services/bankService.types";
import { Event, Guest, User } from "../../../../services/firebase.types";
import { Container, Group, SmallText, Row, TimeTilStart, Title, Amount, SplitRow, Avatars } from "./OpenPoolCard.styles";
import { calculateDuration, hasEventStarted } from "../../../../utils/timeUtils";
import { moneyValueFromMinor } from "../../../../utils/currencyUtils";

interface OpenPoolCardProps {
  amountPaid?: number;
  event: Event;
  activities?: Activity[];
  user: User;
}

export const OpenPoolCard = ({ amountPaid, event, activities, user }: OpenPoolCardProps) => {
  const eventStarted = useMemo(() => hasEventStarted(event.startTime), [event]);
  const duration = useMemo(() => calculateDuration(event.startTime), [event]);
  const userRole = useMemo(() => {
    return event.guests.find(g => g.firstName === user.firstName && g.lastName === user.lastName)?.role ?? 'Collaborator'
  }, [event, user]);

  const totalPaidUsers = useMemo(() => {
    const totalPaid: Guest[] = [];
    activities?.filter(e => e.direction === 'IN')
      .forEach((activity) => {
      const hasPaid = totalPaid.find((g) => g.firstName === activity.guest?.firstName && g.lastName === activity.guest?.lastName);
      if (!hasPaid && activity.guest) {
        totalPaid.push(activity.guest);
      }
    });
    return totalPaid.length;
  }, [activities]);

  const eventActive = event.status?.toLowerCase() === 'active';
  const totalPaid = useMemo(() => activities?.filter(e => e.direction === 'IN')
    .reduce((acc, act) => acc + act.amount.minorUnits, 0), [activities]);
  const totalExpense = useMemo(() => activities?.filter(e => e.direction === 'OUT')
    .reduce((acc, act) => acc + act.amount.minorUnits, 0), [activities]);
  const accountBalance = totalPaid && totalExpense !== undefined ? totalPaid - totalExpense : 0;

  return (
    <Container isactive={`${eventActive && eventStarted}`}>
      <SplitRow>
        <Group>
          <Title>
            {event.title}
          </Title>
          <SmallText>
            {userRole}
          </SmallText>
        </Group>

        <TimeTilStart>
          {eventStarted ? 'Active' : `in ${duration}`}
        </TimeTilStart>
      </SplitRow>

      <div>
        <SmallText>
          Total in whip:
        </SmallText>
        <Amount>
          {moneyValueFromMinor(accountBalance)}
        </Amount>

        <Row>
          <Avatars>
            {event.guests.map((guest, idx) => (
              <div key={`${guest.lastName}_${idx}`} style={{ zIndex: event.guests.length - idx, marginLeft: idx > 0 ? -4 : 0 }}>
                <img src={guest.avatarUrl} alt="" />
              </div>
            ))}
          </Avatars>
          <SmallText>
            {`${totalPaidUsers}/${event.guests.length} paid`}
          </SmallText>
          {amountPaid ? (
            <SmallText>
              You paid: {moneyValueFromMinor(amountPaid)}
            </SmallText>
          ) : null}
        </Row>
      </div>
    </Container>
  );
};
