import { collection, doc, getDocs, query, setDoc } from "@firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { fireStore } from "../services/firebase";
import { Event, Guest, User } from "../services/firebase.types";
import { getItem, STORAGE_KEY } from "../services/storage";

export const useActiveEvent = () => {
  const [event, setEvent] = useState<Event>();
  
  const getEvent = useCallback(async () => {
    const q = query(
      collection(fireStore, "events"),
    );
    const results = await getDocs(q);
    if (!results.empty) {
      const result = results.docs[0].data();
      const data = {
        ...result,
        guests: result.guests
          .sort((a: Guest) => a.accepted ? -1 : 0)
          .sort((a: Guest) => a.role.toLowerCase() === 'admin' ? -1 : 0),
        id: results.docs[0].id,
      } as Event;
      setEvent(data);
    }
  }, []);

  const hasGuestAccepted = useCallback((user: User) => {
    return event?.guests?.find(g =>
      g.firstName?.toLowerCase() === user.firstName?.toLowerCase() &&
      g.lastName?.toLowerCase() === user.lastName?.toLowerCase() &&
      g.accepted) !== undefined;
  }, [event]);

  const updateGuestAccept = useCallback(async (accepted: boolean) => {
    const user = getItem<User>(STORAGE_KEY.User);

    if (!event || !user) {
      return;
    }

    const docRef = doc(fireStore, 'events', event.id);
    const guests = event.guests.map((guest) => {
      if (`${guest.firstName} ${guest.lastName}`.toLowerCase() !== `${user.firstName} ${user.lastName}`.toLowerCase()) {
        return guest;
      }
      return {...guest, accepted};
    });

    await setDoc(docRef, {...event, guests});
  }, [event]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  return {
    event,
    hasGuestAccepted,
    updateGuestAccept,
  };
};
