import { useMemo } from "react";
import { Box, Container, TitleText, ValueText } from "./DateSegment.styles";
import { DateTime } from "luxon";
import { hasEventStarted } from "../../../../utils/timeUtils";

interface DateSegmentProps {
  startTime: string;
}

export const DateSegment = ({ startTime }: DateSegmentProps) => {
  const eventStarted = useMemo(() => hasEventStarted(startTime), [startTime]);
  const durationInDays = useMemo(() => {
    const date = DateTime.fromISO(startTime);
    return date.diffNow('days');  
  }, [startTime]);

  const formattedDate = useMemo(() => {
    return (durationInDays.days < 1) ? 'Today' : DateTime.fromISO(startTime).toFormat('dd MMM yyyy');
  }, [durationInDays, startTime]);

  const formattedTime = useMemo(() => DateTime.fromISO(startTime).toFormat('HH:mm a'), [startTime]);
  return (
    <Container>
      <img src="/assets/images/calendar.svg" alt="" />
      <Box>
        <TitleText>
          {`Date: ${formattedDate}`}
        </TitleText>
        <ValueText>
          {`Start: ${eventStarted ? 'Now' : formattedTime}`}
        </ValueText>
      </Box>
    </Container>
  );
};
