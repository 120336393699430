import { Button } from "../Button/Button";
import { HeaderRow } from "./Header.styles";

interface HeaderProps {
  onLoginClick?: () => void;
}

export const Header = ({ onLoginClick }: HeaderProps) => {
  return (
    <HeaderRow>
      <img src="/assets/images/logo.svg" alt="" />
      {onLoginClick ? (
        <Button onClick={onLoginClick}>
          Sign in
        </Button>
      ) : null}
    </HeaderRow>
  );
};
