
export enum STORAGE_KEY {
  ApiKey = 'apiKey',
  User = 'user'
}

export const getItem = <T>(key: STORAGE_KEY) => {
  try {
    const data = sessionStorage.getItem(key);
    return !data ? undefined : JSON.parse(data) as T;
  } catch (ex) {
    return undefined;
  }
};

export const setItem = <T>(key: STORAGE_KEY, value: T) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const clearAll = () => {
  sessionStorage.clear();
};
