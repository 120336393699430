import styled from "styled-components";

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(93, 38, 206, 0.22);
  box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  gap: 8px;
  align-items: center;
  min-height: 44px;
  margin-bottom: 8px;

  img {
    border-radius: 22px;
    margin-right: 8px;
    height: 42px;
    width: 42px;
  }

  > div {
    display: flex;
    flex-direction: row;
    
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const NameText = styled.div`
  color: white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
`;

export const RoleText = styled.div`
  color: #D1D1D1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.1px;
  margin-top: 4px;
`;

export const StatusBox = styled.div<{accepted: boolean}>`
  border-radius: 5px;
  background: ${({accepted}) => accepted ? '#4CC82B' : '#FFF'};
  box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
  backdrop-filter: blur(6px);
  color: var(--typography-dark, #0B0A1F);
  display: flex;
  padding: 2px 8px;
  align-items: flex-start;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.1px;
`;
