import { useActiveEvent } from "../../hooks/useActiveEvent";
import {
  Background,
  ButtonContainer,
  CloseLink,
  Container,
  LargeButton,
  Page,
  ScrollContainer,
} from "./HomePage.styles";
import { useAccount } from "../../hooks/useAccount";
import { useCallback, useMemo, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import Sheet from 'react-modal-sheet';
import { GuestList } from "../../components/GuestList/GuestList";
import { OpenPoolCard } from "./components/OpenPoolCard/OpenPoolCard";
import { PoolCard } from "./components/PoolCard/PoolCard";
import { PaymentPage } from "../Payment/PaymentPage";
import { addFunds, calculateTransactions } from "../../services/bankService";
import { GroupActivity } from "../../components/GroupActivity/GroupActivity";
import { GuestPayments } from "../../services/bankService.types";

export const HomePage = () => {
  const { event } = useActiveEvent();
  const [isOpen, setIsOpen] = useState(false);
  const {accountBalance, transactions, user, updateAccount} = useAccount();

  const eventPayments: GuestPayments = useMemo(() => {
    if (!user || !event || !transactions) {
      return {};
    }
    return calculateTransactions(user, event, transactions, true)
  }, [event, transactions, user]);

  const potPayments: GuestPayments = useMemo(() => {
    if (!user || !event || !transactions) {
      return {};
    }
    return calculateTransactions(user, event, transactions, false)
  }, [event, transactions, user]);

  const handleAddPayment = useCallback(async () => {
    if (!event?.paymentLink) {
      alert('No payment link provided');
      return;
    }
    // setIsOpen(true);
    await addFunds(event?.paymentLink, 10);
    await updateAccount();
  }, [event?.paymentLink, updateAccount]);

  const handleCompletePayment = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (!event || !user || !accountBalance) {
    return null;
  }

  return (
    <Background>
      <Page>
        <div>
          <img src="/assets/images/logo.svg" alt="" />
          <Container>
            <h1>
              {`Hey ${user.firstName}, how’s`}<br />it going today?
            </h1>
            <div>
              <span>
                Open pools
              </span> 
              <button>
                Create new event
              </button>
            </div>
          </Container>

          <Carousel
            centerMode
            centerSlidePercentage={93}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
          >
            <OpenPoolCard amountPaid={eventPayments.amountPaid} event={event} activities={eventPayments.activities} user={user} />
            <PoolCard amountPaid={potPayments.amountPaid} event={event} balance={accountBalance} user={user} />
          </Carousel>

          {/* {!eventPayments.amountPaid ? ( */}
          <ButtonContainer>
            <LargeButton onClick={handleAddPayment}>
              {`${user.firstName}, is it time to top up?`}
            </LargeButton>
          </ButtonContainer>
          {/* ) : null} */}

        </div>
        <ScrollContainer>
          {eventPayments.amountPaid
            ? <GroupActivity activities={eventPayments.activities} eventTitle={event.title} />
            : <GuestList guests={event.guests} />}
        </ScrollContainer>
      </Page>

      <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)} detent='content-height'>
        <Sheet.Container>
          <Sheet.Header>
            <CloseLink onClick={() => setIsOpen(false)}>
              Close            
            </CloseLink>
          </Sheet.Header>
          <Sheet.Content>
            <PaymentPage event={event} user={user} onClose={handleCompletePayment} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </Background>
  );
};
