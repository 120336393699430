import { useCallback, useEffect, useState } from "react";
import { getAccounts, getBalanceForAccount, getHolderName, getTransactionsForAccount } from "../services/bankService";
import { Account, AccountBalance, AccountHolder, AccountSpaces, FeedItem } from "../services/bankService.types";
import { User } from "../services/firebase.types";
import { STORAGE_KEY, getItem } from "../services/storage";

export const useAccount = () => {
  const [user, setUser] = useState<User>();
  const [account, setAccount] = useState<Account>();
  const [accountHolder, setAccountHolder] = useState<AccountHolder>();
  const [accountBalance, setAccountBalance] = useState<AccountBalance>();
  const [spendingSpaces, setSpendingSpaces] = useState<AccountSpaces>();
  const [transactions, setTransactions] = useState<FeedItem[]>();

  const updateAccount = useCallback(async () => {
    try {
      const accounts = await getAccounts();
      if (accounts && accounts.length > 0) {
        const mainAccount = accounts[0];
        const holder = await getHolderName();
        const balance = await getBalanceForAccount(mainAccount.accountUid);
        // const spaces = await getSpendingSpaces(mainAccount.accountUid);
        const feedItems = await getTransactionsForAccount(mainAccount.accountUid, mainAccount.defaultCategory) as any;

        const user = getItem<User>(STORAGE_KEY.User);

        setUser(user);
        setAccount(mainAccount);
        setAccountHolder(holder);
        setAccountBalance(balance);
        // setSpendingSpaces(spaces);
        setTransactions(feedItems);
      }
    } catch (ex) {
      alert('Failed to load accounts');
      console.log('Failed to load accounts');
    }
  }, []);

  useEffect(() => {
    updateAccount();
  }, [updateAccount]);
  
  return {
    account,
    accountHolder,
    accountBalance,
    spendingSpaces,
    transactions,
    user,
    updateAccount,
  };
};
