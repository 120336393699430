import styled from "styled-components";
import { Button } from "../../components/Button/Button";

export const Background = styled.div`
  background: rgba(13, 1, 62, 0.10);
  background-image: url('/assets/images/background01.png');
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    height: 100%;
  }
`;

export const Container = styled.div`
  padding: 0 24px;
  padding-top: 33px;
`;

export const TitleText = styled.div`
  color: var(--background-light, #f9f9f9);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 4px;
`;

export const CheckList = styled.div`
  padding-top: 21px;
`;

export const CheckItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;

  img {
    padding-right: 12px;
  }
`;

export const LargeButton = styled(Button)`
  margin: 30px 0;
  padding: 4px 16px;
  width: 114.393px;
  height: 50px;
`;

export const CommentContainer = styled.div<{topInset: number}>`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  font-size: 8px;
  font-weight: 700;
  line-height: 10px;  
  color: ${({theme}) => theme.colors.white};
  flex-shrink: 0;
  border-radius: 10px;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px 6px 14px 0px rgba(15, 9, 28, 0.30);
  backdrop-filter: blur(26px);
  margin-top: ${({topInset}) => topInset}px;
`;

export const LeftComment = styled(CommentContainer)`
  align-self: flex-start;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 8px;
`;

export const RightComment = styled(CommentContainer)`
  align-self: flex-end;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-right: 8px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    align-self: center;
    bottom: 0;
    width: 191px;
  }
`;
