import { fireStore } from "./firebase";
import { doc, getDoc } from "@firebase/firestore";

export interface Config {
  mockData: boolean;
  collectTransactionsAheadOfEventInHours: number;
};

let apiConfig: Config;

export const localConfig = () => {
  return apiConfig;
};

export const getConfig = async () => {
  if (apiConfig) {
    return apiConfig;
  }

  const docRef = doc(fireStore, 'config', 'config');
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return;
  }

  apiConfig = docSnap.data() as Config;
  return apiConfig;
};
