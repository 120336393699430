import styled from "styled-components";
import { Button } from "../../components/Button/Button";

export const Background = styled.div`
  background: rgba(13, 1, 62, 0.10);
  background-image: url('/assets/images/background02.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    height: 100%;

    > img:first-child {
      padding: 24px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 24px;
`;

export const TitleText = styled.div`
  color: var(--background-light, #f9f9f9);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 4px;
`;

export const FieldContainer = styled.div`
  margin-top: 32px;

  input {
    border-radius: 100px;
    border: 1px solid rgba(224, 224, 224, 0.50);
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
    backdrop-filter: blur(6px);
    height: 35px;
    padding: 8px 24px;
    width: calc(100% - 48px);
    color: var(--background-light, #F9F9F9);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.1px;
    outline: none;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-text-fill-color: white !important;
      transition: background-color 5000s ease-in-out 0s;
  }
`;

export const LargeButton = styled(Button)`
  margin: 32px 0;
  padding: 4px 16px;
  height: 50px;
`;