import styled from "styled-components";

export const Container = styled.div<{isactive: string}>`
    ${({isactive}) => {
        if (isactive === 'true') {
            return `
                background: rgba(93, 38, 206, 0.20);
                background-image: url('/assets/images/active.png');
                border-radius: 12px;
                border: 1px solid rgba(255, 255, 255, 0.20);
                box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
                backdrop-filter: blur(35px);
            `;
        }

        return `
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 0.20);
            background: rgba(93, 38, 206, 0.20);
            box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
            backdrop-filter: blur(6px);
        `;
    }}
    display: flex;
    height: 188px;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 4px 0 30px;

    > div:last-child {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const Title = styled.div`
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: -0.1px;
`;

export const SmallText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.1px;
`;

export const Amount = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 50px; /* 104.167% */
    letter-spacing: -0.1px;
`;

export const Row = styled.div`
    align-items: center;
    display: flex;
    
    ${SmallText} {
        margin-left: 8px;
    }
`;

export const SplitRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;


export const Group = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`;

export const TimeTilStart = styled.div`
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.19);
    box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
    backdrop-filter: blur(6px);
    padding: 2px 8px;
    align-items: flex-start;
    color: #FFF;
    text-align: right;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.1px;
`;

export const Avatars = styled.div`
    align-items: center;
    display: flex;

    div {
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.65);
        background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
        border-radius: 8px;
        height: 16px;
        width: 16px;
        img {
            border-radius: 7px;
            height: 14px;
            width: 14px;
        }
    }
`;
