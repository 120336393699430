import { Box, Container, TitleText, ValueText } from "./LocationSegment.styles";

interface LocationSegmentProps {
  venue: string;
  location: string;
}

export const LocationSegment = ({ venue, location }: LocationSegmentProps) => {
  return (
    <Container>
      <img src="/assets/images/location.svg" alt="" />
      <Box>
        <TitleText>
          {venue}
        </TitleText>
        <ValueText>
          {location}
        </ValueText>
      </Box>
    </Container>
  );
};
