import { addFunds } from "../../services/bankService";
import { Event, User } from "../../services/firebase.types";
import {
  AdminGuest,
  BlackButton,
  InputContainer,
  Page,
  PaymentButton,
  PaymentTitle,
  Statement,
} from "./PaymentPage.styles";
import { useCallback, useMemo, useState } from "react";

interface PaymentPageProps {
  event: Event;
  user: User;
  onClose: () => void;
}

export const PaymentPage = ({ event, user, onClose }: PaymentPageProps) => {
  const [amount, setAmount] = useState('10.00');
  const handlePayment = useCallback(async () => {
    if (!Number(amount) || parseFloat(amount) < 1) {
      alert('Provide a valid amount greater than £1 to make payment');
      return;
    }
    await addFunds(event.paymentLink, parseFloat(amount));
    onClose();
  }, [amount, event.paymentLink, onClose]);

  const adminGuest = useMemo(() => event.guests.find(g => g.role?.toLowerCase() === 'admin'), [event]);
 
  return (
    <Page>
      <h1>
        Add your share to<br />"{event?.title}" pool
      </h1>

      <InputContainer>
        <div>£</div>
        <input type="number" onChange={e => setAmount(e.currentTarget.value)} value={amount} />
      </InputContainer>

      <AdminGuest>
        <img src={adminGuest?.avatarUrl} alt="" />
        <div>{adminGuest?.firstName}</div>
      </AdminGuest>
      <Statement>
        Hey {user.firstName}, add £10 into the whip for {event.title}. No getting away without a round now!
      </Statement>

      <PaymentTitle>
        How do you want to contribute?
      </PaymentTitle>
      <BlackButton onClick={handlePayment}>
        <img src="/assets/images/applepay.svg" alt="Apple Pay" />
      </BlackButton>
      {/* <BlackButton onClick={handlePayment}>
        <img src="/assets/images/gpay.svg" alt="Google Pay" />
      </BlackButton>
      <YellowButton onClick={handlePayment}>
        <img src="/assets/images/paypal.svg" alt="Paypal" />
      </YellowButton>
      <PaymentButton onClick={handlePayment}>
        Open banking
      </PaymentButton> */}
      <PaymentButton onClick={handlePayment}>
        Debit card
      </PaymentButton>
    </Page>
  );
};
