import { styled } from "styled-components";

export const Button = styled.button`
  background: none;
  border: none;
  background-color: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.purple};
  box-shadow: 0px 2px 12px 0px rgba(25, 15, 45, 0.25);
  border-radius: 100px;
  backdrop-filter: blur(6px);
  padding: 4px 8px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 
  letter-spacing: -0.1px;
`;
