import { Activity } from "../../services/bankService.types";
import { moneyValueFromMinor } from "../../utils/currencyUtils";
import { ActivityText, Container } from "./ActivityItem.styles";

interface ActivityItemProps {
  activity?: Activity;
  eventTitle: string;
}

export const ActivityItem = ({ activity, eventTitle }: ActivityItemProps) => {
  if (!activity) {
    return null;
  }

  return (
    <Container>
      {activity.direction === 'IN' ? (
        <ActivityText>
          {activity.isUser ? `Your money was successfully added to the pool!` : `${activity.guest?.firstName} added ${moneyValueFromMinor(activity.amount.minorUnits)} to ‘${activity.transaction.reference}`}
        </ActivityText>
      ) : (
        <ActivityText>
          {activity.isUser ? `Your have spent ${moneyValueFromMinor(activity.amount.minorUnits)}` : `${activity.guest?.firstName} has spent ${moneyValueFromMinor(activity.amount.minorUnits)} on ${activity.transaction.counterPartyName}`}
        </ActivityText>
      )}
    </Container>
  );
};
