import styled from "styled-components";
import { Button } from "../../components/Button/Button";

export const Background = styled.div`
  background: rgba(13, 1, 62, 0.10);
  /* background-image: url('/assets/images/background02.png'); */
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    height: 100%;

    > img:first-child {
      padding: 24px 24px 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 160px);
`;

export const TitleText = styled.div`
  color: #FFF;
  font-size: 32px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.1px;
`;

export const ImageContent = styled.div`
  position: absolute;
  margin: 70px 24px;

  > ${TitleText} {
    margin-bottom: 11px;
  }
`;

export const LargeButton = styled(Button)`
  margin: 32px 0;
  padding: 4px 16px;
  height: 50px;
  margin: 0 24px;
`;

export const Link = styled.div`
  padding: 24px;
  align-self: center;
  text-decoration-line: underline;
  color: var(--background-light, #F9F9F9);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
`;

export const Footer = styled.div`
  background: rgb(13, 1, 62);
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
