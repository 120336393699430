import { Guest } from "../../services/firebase.types";
import { GuestItem } from "../GuestItem/GuestItem";
import { Container, TitleSubText } from "./GuestList.styles";

interface GuestListProps {
  guests: Guest[];
}

export const GuestList = ({ guests }: GuestListProps) => {
  return (
    <Container>
      <TitleSubText>
        Guests list
      </TitleSubText>

      {guests.map((guest, idx) => (
        <GuestItem key={`${guest.lastName}_${idx}`} guest={guest} />
      ))}
    </Container>
  );
};