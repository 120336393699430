import styled from "styled-components";

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px 6px 14px 0px rgba(15, 9, 28, 0.30);
  backdrop-filter: blur(26px);
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const ActivityText = styled.div`
  color: var(--background-light, #F9F9F9);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
`;
