import { useEffect, useState } from "react";
import {
  Background,
  Container,
  FieldContainer,
  LargeButton,
  Page,
  TitleText,
} from "./SignInPage.styles";
import { fireStore } from "../../services/firebase";
import { collection, getDocs, query, where } from "@firebase/firestore";
import { STORAGE_KEY, clearAll, setItem } from "../../services/storage";
import { User } from "../../services/firebase.types";
import { useNavigate } from "react-router";
import { useActiveEvent } from "../../hooks/useActiveEvent";

export const SignInPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const { event, hasGuestAccepted } = useActiveEvent();
  const navigate = useNavigate();

  useEffect(() => {
    clearAll();
  }, []);

  const handleSignIn = async () => {
    try {
      if (!event?.apiKey) {
        return;
      }

      const q = query(
        collection(fireStore, "users"),
        where("phoneNumber", "==", phoneNumber),
        where("password", "==", password),
      );
      const results = await getDocs(q);
      if (results.empty) {
        alert('Unable to find an account with that phone number.');
        console.log('No matching accounts.');
        return;
      }
  
      const user = {
        ...results.docs[0].data(),
        id: results.docs[0].id,
      } as User;

      setItem<User>(STORAGE_KEY.User, user);
      setItem<string>(STORAGE_KEY.ApiKey, event.apiKey);

      setPhoneNumber('');
      setPassword('');

      const accepted = hasGuestAccepted(user);
      navigate(accepted ? '/home' : '/accept-invite');
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <Background>
      <Page>
        <div>
          <img src="/assets/images/logo.svg" alt="" />
          <Container>
            <h1 style={{ paddingBottom: 6 }}><strong>Sign in to Chippin</strong></h1>
            <TitleText>Enter your mobile number to get started</TitleText>

            <FieldContainer>
              <TitleText>Mobile number</TitleText>
              <input onChange={e => setPhoneNumber(e.currentTarget.value)} value={phoneNumber} type="tel" />
            </FieldContainer>

            <FieldContainer>
              <TitleText>Pin</TitleText>
              <input onChange={e => setPassword(e.currentTarget.value)} value={password} type="password" />
            </FieldContainer>

            <LargeButton disabled={!event?.apiKey} onClick={handleSignIn}>
              Sign in
            </LargeButton>
          </Container>
        </div>
      </Page>
    </Background>
  );
};
