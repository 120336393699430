import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { theme } from "./theme/theme";
import { LandingPage } from "./pages/Landing/LandingPage";
import { SignInPage } from "./pages/SignIn/SignInPage";
import { AcceptInvitePage } from "./pages/AcceptInvite/AcceptInvitePage";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { HomePage } from "./pages/Home/HomePage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="sign-in" element={<SignInPage />} />
          <Route
            path="accept-invite"
            element={
              <ProtectedRoute>
                <AcceptInvitePage />
              </ProtectedRoute>
            }
          />
          <Route path="home" element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          } />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
