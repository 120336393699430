import { Guest } from "../../services/firebase.types";
import { Container, NameText, RoleText, StatusBox } from "./GuestItem.styles";

interface GuestProps {
  guest: Guest;
}

export const GuestItem = ({ guest }: GuestProps) => {
  return (
    <Container>
      <div>
        <img src={guest.avatarUrl} alt="" />
        <div className="container">
          <NameText>
            {`${guest.firstName} ${guest.lastName}`}
          </NameText>
          <RoleText>
            {guest.role}
          </RoleText>
        </div>
      </div>
      <StatusBox accepted={guest.accepted}>
        {guest.accepted ? 'Going' : 'Invited'}
      </StatusBox>
    </Container>
  );
};
