import styled from "styled-components";

export const Page = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  overflow-y: auto;

  h1 {
    color: #242039;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 35.2px */
    letter-spacing: -0.1px;
  }
`;

export const InputContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  margin-top: 40px;
  margin-bottom: 13px;

  > div {
    color: #0B0A1F;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.1px;
  }

  > input {
    border: none;
    text-align: center;
    outline: none;
    color: var(--typography-dark, #0B0A1F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px; /* 100% */
    letter-spacing: -0.1px;
    width: 100%;
  }
`;

export const AdminGuest = styled.div`
  align-items: center;
  display: flex;

  img {
    border-radius: 12px;
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }

  div {
    color: var(--typography-dark, #0B0A1F);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.1px;
  }
`;

export const Statement = styled.div`
  color: var(--typography-light, #9D9BA7);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.1px;
  margin-top: 8px;
`;

export const PaymentTitle = styled.div`
  color: var(--typography-dark, #0B0A1F);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.1px;
  margin-top: 40px;
  margin-bottom: 16px;
`;

export const PaymentButton = styled.button`
  border-radius: 100px;
  border: 1px solid rgba(157, 155, 167, 0.20);
  background: var(--light, #FDFDFD);
  box-shadow: 0px 8px 12px 0px rgba(58, 69, 125, 0.08);
  display: flex;
  height: 50px;
  min-height: 50px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const BlackButton = styled.button`
  border-radius: 100px;
  border: 0px solid #000;
  background: #000;
  display: flex;
  height: 50px;
  min-height: 50px;
  padding: 8px 16px;
  color: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const YellowButton = styled.button`
  border-radius: 100px;
  background: var(--pay-pal-yellow, #FFC43A);
  border: none;
  display: flex;
  height: 50px;
  min-height: 50px;
  padding: 8px 16px;
  color: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;
